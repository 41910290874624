<template>
  <div class="test">
    <div id="map" class="GvolContainer" ref="map"></div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import * as turf from "@turf/turf";

import { getAllMarkDataApi } from "@/request/api";
import "ol/ol.css";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Style, Fill, Text, Stroke } from "ol/style";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import Collection from "ol/Collection";
import * as Interaction from "ol/interaction";

export default {
  components: {},
  data() {
    return {
      cameraList: [],
      id: "",
      titleLayer: {},
      polygonLayer: null,
      myPolygonSource: new VectorSource({
        wrapX: false,
        features: [],
      }),
      polygonsData: [],
      olMap: {},
      titleFeatures: [],
      vsource: new VectorSource({}),
      centerVal: 0,
    };
  },

  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
      userInfo: (state) => state.user.userInfo,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getAllMarkData();
  },
  methods: {
    ...mapMutations("app", ["SET_CAMERALIST"]),
    initMap() {
      let position = this.farmInfo.position;
      position = position.split(",");
      window.olMap = new Map({
        target: "map",
        layers: [
          // 星图地球底图层
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          // 星图地球注记层
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.polygonLayer,
          this.titleLayer,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+position[0], +position[1]],
          zoom: this.farmInfo.zoom,
          maxZoom: 18,
          constrainResolution: true, // 设置缩放级别为整数
          smoothResolutionConstraint: false, // 关闭无级缩放地图
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new Interaction.defaults({
          doubleClickZoom: false, //屏蔽双击放大事件
        }),
      });

      this.hoverPoint();
      this.clickPoint();
    },
    // 清除多边形
    clearDraw() {
      this.myPolygonSource.clear();
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: x,
        }),
        stroke: new Stroke({
          width: 4,
          color: y,
        }),
      });
      return style;
    },
    featureStyle2(x, y) {
      let style = new Style({
        // fill: new Fill({
        //   // width: 18,
        //   color: x,
        // }),
        stroke: new Stroke({
          width: 10,
          color: y,
        }),
      });
      return style;
    },
    showPolygon() {
      this.clearDraw();
      let array = this.polygonsData;
      let features = [];
      for (let i = 0; i < array.length; i++) {
        let coord = array[i].coord;
        let x = array[i].color[0];
        let y = array[i].color[1];
        let z = array[i].name;

        // 眼
        let f = new Feature({
          geometry: new Polygon([coord]),
          type: "isPolygon",
          info: array[i],
        });
        f.setStyle(this.featureStyle(x, y));
        f.setProperties({
          name: z,
        });
        features.push(f);
      }

      this.myPolygonSource.addFeatures(features);

      this.polygonLayer = new VectorLayer({
        zIndex: 3,
        minZoom: 8,
        source: this.myPolygonSource,
        style: new Style({
          fill: new Fill({
            color: "",
          }),
          stroke: new Stroke({
            width: 4,
            color: "",
          }),
        }),
      });
    },

    hoverPoint() {
      window.olMap.on("pointermove", (e) => {
        if (e.dragging) return;
        let feature = window.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        });
        if (!feature) {
          this.showPolygon();
          return;
        }

        let info = feature.get("info");
        if (this.id != info.id) {
          this.showPolygon();
        }
        this.id = info.id;
        let f = new Feature({
          geometry: new Polygon([info.coord]),
          type: "isPolygon",
          info,
        });
        f.setStyle(this.featureStyle2(info.color[0], info.color[1]));
        f.setProperties({
          name: info.name,
        });
        this.myPolygonSource.addFeatures([f]);
      });
    },
    clickPoint() {
      window.olMap.on("singleclick", (e) => {
        let feature = window.olMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          if (feature.get("type") === "isPolygon") {
            this.$router.replace({
              name: "equipment",
              query: { type: "quantity" },
            });
          }
        }
      });
    },
    // 添加标题开始
    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "rgba(22,133,144,.3)",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },
    addTitles() {
      this.titleLayer = new VectorLayer({
        zIndex: 3,
        minZoom: 13,
        source: new VectorSource({
          features: new Collection(
            this.titleFeatures.map((featureOptions) => {
              const feature = new Feature({
                geometry: featureOptions.geometry,
              });
              feature.setStyle(this.createTitleStyle(featureOptions));
              return feature;
            })
          ),
          format: new GeoJSON(),
        }),
      });
    },

    // 获取设备类型
    async getAllMarkData() {
      const { code, results } = await getAllMarkDataApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.cameraList = results;

      this.cameraList.forEach((item) => {
        item.items.forEach((item2) => {
          // 处理坐标
          if (item2.mark_type == 3 && item2.mark_data) {
            let polygonsItemObj = { coord: [] };
            if (typeof item2.mark_data === "string") {
              item2.mark_data = JSON.parse(item2.mark_data);
            }
            item2.mark_data.forEach((item3) => {
              polygonsItemObj.coord.push([item3[0], item3[1]]);
            });
            polygonsItemObj.color = [item2.body_color, item2.border_color];
            polygonsItemObj.name = `polygon${item2.id}`;
            polygonsItemObj.id = item2.id;
            polygonsItemObj.title = item.group;
            let polygon = turf.polygon([item2.mark_data]);
            this.centerVal = turf.centroid(polygon).geometry.coordinates;
            this.polygonsData.push(polygonsItemObj);
            // 标题
            let titleMarkerObj = {
              text: item2.title,
              geometry: new Point(this.centerVal),
            };
            this.titleFeatures.push(titleMarkerObj);
          }
        });
        this.$forceUpdate();
      });

      this.addTitles();
      this.showPolygon();
      setTimeout(() => {
        this.initMap();
      }, 80);
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  width: 100%;
  height: 100%;
  background: #051218;
  /deep/.GvolContainer {
    width: 100vw !important;
    height: 100vh !important;
    position: relative;
    color: #fff;
  }
  .equipment,
  .collection,
  .weather {
    width: 282px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top: 6px solid #125397;
    padding: 23px 13px;
    background-color: #0f243d;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 106px;
    left: 78px;
    z-index: 1999;
    transition: all 0.5s;
    p {
      margin: 0 0 10px 0;
      &:first-of-type {
        font-weight: bold;
        font-size: 18px;
        margin: 0 0 20px 0;
      }
    }
    .eq-title {
      color: #3e90e5;
      font-size: 16px;
      margin-bottom: 17px;
      text-align: center;
    }

    .eq-lists {
      display: flex;
      width: 100%;
      flex-direction: column;

      .eq-items {
        width: 100%;
        height: 41px;
        background: rgba(62, 144, 229, 0.25);
        border: 1px solid #3e90e5;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 6px 14px;
        justify-content: space-between;
        margin-bottom: 14px;

        .left-div {
          display: flex;
          align-items: center;
          .icon1-img {
            width: 30px;
            height: 30px;
            margin-right: 16px;
          }
        }
        .name,
        .val {
          font-size: 14px;
          color: #fff;
          font-weight: normal;
        }
        .my-switch {
          /deep/.el-switch__core {
            background-color: #d8e5f0;
          }

          /deep/.el-switch__core:after {
            background-color: #a6aeb5;
          }
        }

        .my-switch.active {
          /deep/.el-switch__core:after {
            background-color: #2878ff;
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
}
</style>
